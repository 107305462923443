/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import clsx from 'clsx'
import { phoneNumber } from '@constants/configuration'
import { useAddressLink } from '@constants/links'
import { PostalAddressFormat } from '@model/postalAddress'
import { DrawerProps, IconButton, Link, List, MenuItem, SwipeableDrawer } from '@mui/material'
import { Menu } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import {
  ButtonClose,
  LinkFacebook,
  LinkInstagram,
  LinkPhone,
  LinkViber,
  LinkWhatsapp,
  RouterLink
} from '@controls/buttons'
import { PostalAddress } from '@controls/panels'
import { FlexContainer } from '@layout/FlexContainer'
import { LanguageMenuItemProps, MenuItemValue } from '../..'
import styles from './MobileMenu.module.css'

type Props = Omit<DrawerProps, 'open' | 'onClose'> &
  Readonly<{
    menuItems: ReadonlyArray<MenuItemValue>
    languageItems: ReadonlyArray<LanguageMenuItemProps>
  }>

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

export const MobileMenu: React.FC<Props> = ({ menuItems, languageItems, ...drawerProps }) => {
  const location = useLocation()
  const { i18n, t } = useTranslation()
  const addressLink = useAddressLink()

  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <>
      <SwipeableDrawer
        anchor="top"
        className={styles.mobileMenuContainer}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        {...drawerProps}
      >
        <ButtonClose className={styles.closeMenuBtn} fullWidth onClick={() => setMenuOpen(false)} />
        <List>
          {menuItems.map(({ to, title, items }, index) =>
            hasValue(to) && isEmpty(items) ? (
              <MenuItem
                key={`menu-item-${index}`}
                selected={to === location.pathname}
                //to={to}
                //onClick={hasValue(to) ? handleMenuItemClick(to) : undefined}
              >
                <RouterLink to={to}>{title}</RouterLink>
              </MenuItem>
            ) : (
              <MenuItem key={`menu-item-${index}`}>
                <Accordion
                  className={styles.accordionMenuItem}
                  sx={{
                    '&:before': {
                      display: 'none' // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <span>{title}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className={styles.submenuList}>
                      {items?.map(({ to, title }, subIndex) =>
                        to ? (
                          <MenuItem
                            key={`menu-item-${index}-sub-item-${subIndex}`}
                            selected={to === location.pathname}
                            //onClick={hasValue(to) ? handleMenuItemClick(to) : undefined}
                          >
                            <RouterLink to={to}>{title}</RouterLink>
                          </MenuItem>
                        ) : null
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </MenuItem>
            )
          )}
        </List>
        <FlexContainer fixedDirection className={styles.languageSwitcherContainer}>
          {languageItems.map(({ title, href }, index) => (
            <MenuItem key={`language-${index}`} selected={title?.toLowerCase() === i18n.language}>
              <Link href={href}>{title}</Link>
            </MenuItem>
          ))}
        </FlexContainer>
        <FlexContainer alignItemsCenter className={styles.linksColumn}>
          <FlexContainer alignItemsCenter noGap>
            <Link href={addressLink} className={styles.addressLink}>
              <PostalAddress format={PostalAddressFormat.Short} />
            </Link>

            <FlexContainer fixedDirection fitWidth>
              <FlexContainer fixedDirection alignItemsCenter fitWidth responsiveGap>
                <span>{t('layout.header.items.weekdays')}</span>|<span>{t('layout.header.items.hours1')}</span>
              </FlexContainer>
              <FlexContainer fixedDirection alignItemsCenter fitWidth responsiveGap>
                <span>{t('layout.header.items.weekend')}</span>|<span>{t('layout.header.items.hours2')}</span>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>

          <LinkPhone phoneNumber={phoneNumber} className={clsx(styles.phoneLink)} />

          <FlexContainer fixedDirection justifyContentCenter>
            <LinkFacebook />
            <LinkInstagram />
            <LinkWhatsapp phoneNumber={phoneNumber} />
            <LinkViber phoneNumber={phoneNumber} />
          </FlexContainer>
        </FlexContainer>
      </SwipeableDrawer>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setMenuOpen(true)}
        color="inherit"
      >
        <Menu />
      </IconButton>
    </>
  )
}
