import * as React from 'react'
import { usePostalAddress } from '@hooks/Translation/usePostalAddress'
import { formatterPostalAddress } from '@model/postalAddress/utils'
import { PostalAddressFormat } from '@model/postalAddress'

type Props = Readonly<{
  format: PostalAddressFormat
}>

export const PostalAddress: React.FC<Props> = ({ format }) => {
  const address = usePostalAddress()
  const addressFormatter = formatterPostalAddress(format)

  return <span>{addressFormatter(address)}</span>
}
