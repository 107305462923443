import * as React from 'react'
import clsx from 'clsx'
import pageStyles from '@assets/css/page.module.css'
import { CommonComponentProps } from '@controls/types'
import { hasValue } from '@digital-magic/ts-common-utils'

type Props = CommonComponentProps &
  Readonly<{
    subtitle?: string
  }>

export const PageContent: React.FC<Props> = ({ children, className, subtitle }) => {
  return (
    <div className={clsx(className, pageStyles.content)}>
      {hasValue(subtitle) && <h2 className={pageStyles.pageSubtitle}>{subtitle}</h2>}
      {children}
    </div>
  )
}
