import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Button, ButtonProps } from '@mui/material'
import styles from './ButtonClose.module.css'
import clsx from 'clsx'

export type ButtonCloseProps = Omit<ButtonProps, 'children'>

export const ButtonClose: React.FC<ButtonCloseProps> = ({
  className,
  color = 'primary',
  variant = 'text',
  ...props
}) => (
  <Button className={clsx(styles.buttonClose, className)} variant={variant} color={color} {...props}>
    <CloseIcon aria-label="close" />
  </Button>
)
