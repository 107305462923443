import * as React from 'react'
import { buildViberLink } from '@constants/links'
import SvgViberColoredImg from '@assets/icons/social/viber.svg'
import SvgViberBWImg from '@assets/icons/viberIcon.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkViberProps = Readonly<{
  phoneNumber: string
  colored?: boolean
  size?: IconLinkProps['size']
}>

export const LinkViber: React.FC<LinkViberProps> = ({ phoneNumber, colored, size }) => (
  <IconLink
    //invalid link
    href={buildViberLink(phoneNumber)}
    title={phoneNumber}
    src={colored ? SvgViberColoredImg : SvgViberBWImg}
    alt={phoneNumber}
    size={size}
    color={colored && size == 'square' ? 'purple' : 'blank'}
  />
)
