import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import clsx from 'clsx'
import { phoneNumber } from '@constants/configuration'
import { UserLanguageEnumSchema } from '@model/language'
import { commonColors } from '@styles/theme/colors'
import { AppBar, Button, Link, Toolbar, useMediaQuery } from '@mui/material'
import logoImg from '@assets/icons/logo.svg'
import phoneImg from '@assets/icons/phone.svg'
import pageStyles from '@assets/css/page.module.css'
import { useAuthContext } from '@hooks/AuthContext'
import { useLanguage } from '@hooks/useLanguage'
import { useTheme } from '@hooks/useTheme'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { useAuthStore } from '@stores/useAuthStore'
import { RouterLink } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import { LanguageMenuItemProps, useMenuItems } from '..'
import { MobileMenu } from './MobileMenu/MobileMenu'
import { DesktopMenu } from './DesktopMenu/DesktopMenu'
import styles from './Menu.module.css'

export const Menu: React.FC = () => {
  const { t } = useTranslation()
  const { logout } = useAuthContext()
  const { routes, currentRoutePathForLanguage } = useLanguage()
  const userName = useAuthStore((state) => state.auth?.userName)
  const { currentTheme } = useTheme()
  const { languageTranslation } = useEnumTranslation()
  const isUpMd = useMediaQuery(currentTheme.breakpoints.up('md'))
  const menuItems = useMenuItems()

  const handleLogoutClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    logout()
  }

  const languageItems: ReadonlyArray<LanguageMenuItemProps> = React.useMemo(
    () =>
      UserLanguageEnumSchema.options.map((lang) => ({
        title: languageTranslation(lang),
        href: currentRoutePathForLanguage(lang)
      })),
    [languageTranslation, currentRoutePathForLanguage]
  )

  return (
    <AppBar className={clsx(styles.outerContainer, styles.appBar)}>
      <Toolbar disableGutters className={clsx(styles.innerContainer, pageStyles.container,styles.toolBar)}>
        {/* the logo */}
        <RouterLink className={styles.logoContainer} display={'flex'} alignItems={'center'} to={routes.Index}>
          <img width="273px" height="43" className={styles.logo} src={logoImg} alt="logo" />
        </RouterLink>
        <FlexContainer fixedDirection responsiveGap alignItemsCenter className={styles.rightSideContainer}>
          <Link href={`tel:${phoneNumber}`} sx={{ display: { xs: 'flex', md: 'none' } }}>
            <img className={styles.phoneIcon} src={phoneImg} alt={`phone: ${phoneNumber}`} />
          </Link>
          {isUpMd ? (
            <DesktopMenu menuItems={menuItems} languageItems={languageItems} />
          ) : (
            <MobileMenu menuItems={menuItems} languageItems={languageItems} />
          )}
          {hasValue(userName) && (
            <FlexContainer fixedDirection fitWidth className={styles.userPanelContainer}>
              <span className={styles.userName} color={commonColors.grayB7}>
                {userName}
              </span>
              <Button variant="text" onClick={handleLogoutClick}>
                {t('global.buttons.logout')}
              </Button>
            </FlexContainer>
          )}
        </FlexContainer>
      </Toolbar>
    </AppBar>
  )
}
