import { MenuProps } from '@mui/material/Menu/Menu'
import { commonColors } from '@styles/theme/colors'
import { PaletteMode } from '@mui/material'

// Will be required when darkTheme will be introduced
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const menuListProps = (currentTheme: PaletteMode): Partial<MenuProps> => ({
  MenuListProps: {
    sx: {
      backgroundColor: currentTheme === 'light' ? commonColors.white : commonColors.black,
      '.MuiMenuItem-root': {
        fontSize: '14px',
        fontWeight: 500
      }
    }
  }
})
