/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { controlClassName } from '@digital-magic/react-common'
import { Button, Link, MenuItem } from '@mui/material'
import SvgChevronIcon from '@assets/icons/chevronIcon.svg?react'
import { useTheme } from '@hooks/useTheme'
import { useLanguage } from '@hooks/useLanguage'
import { RouterLink } from '@controls/buttons'
import { MenuTrigger } from '@controls/Menu/MenuTrigger'
import { menuListProps } from '@layout/Page'
import { FlexContainer } from '@layout/FlexContainer'
import { LanguageMenuItemProps, MenuItemValue } from '../..'
import styles from './DesktopMenu.module.css'

type Props = Readonly<{
  menuItems: ReadonlyArray<MenuItemValue>
  languageItems: ReadonlyArray<LanguageMenuItemProps>
}>

export const DesktopMenu: React.FC<Props> = ({ menuItems, languageItems }) => {
  const location = useLocation()
  const { currentTheme } = useTheme()
  const { language } = useLanguage()

  return (
    <FlexContainer fixedDirection justifyContentSpaceBetween className={styles.desktopMenuContainer}>
      <FlexContainer fixedDirection alignItemsCenter noGap className={styles.desktopMenuItemsContainer}>
        {menuItems.map(({ title, to, items }, index) =>
          hasValue(to) && isEmpty(items) ? (
            <NavLink
              key={`desktop-menu-item-${index}`}
              to={to}
              className={({ isActive }) => (isActive ? controlClassName.Active : '')}
            >
              {title}
            </NavLink>
          ) : (
            <MenuTrigger
              {...menuListProps(currentTheme.palette.mode)}
              key={`desktop-menu-item-${index}`}
              renderMenu={({ closeHandler }) =>
                items?.map(({ to, title }, subIndex) =>
                  to ? (
                    <MenuItem key={`desktop-menu-item-${index}-${subIndex}`} selected={to === location.pathname}>
                      <RouterLink to={to} onClick={(e) => closeHandler(e.currentTarget)}>
                        {title}
                      </RouterLink>
                    </MenuItem>
                  ) : null
                )
              }
            >
              {({ openHandler }) => (
                <Button
                  variant="text"
                  className={styles.buttonWithChevron}
                  endIcon={<SvgChevronIcon />}
                  onClick={(e) => openHandler(e.currentTarget)}
                >
                  {title}
                </Button>
              )}
            </MenuTrigger>
          )
        )}
      </FlexContainer>
      {/* language switcher */}
      <MenuTrigger
        renderMenu={() =>
          languageItems.map(({ title, href }, index) => (
            <MenuItem key={`language-${index}`} className={styles.languageMenuItem}>
              <Link href={href}>{title}</Link>
            </MenuItem>
          ))
        }
        {...menuListProps(currentTheme.palette.mode)}
      >
        {({ openHandler }) => (
          <Button variant="text" className={styles.languageBtn} onClick={(e) => openHandler(e.currentTarget)}>
            {language}
          </Button>
        )}
      </MenuTrigger>
    </FlexContainer>
  )
}
