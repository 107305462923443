import * as React from 'react'
import { NavLinkProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'

export type MenuItemValue = Readonly<{
  title: string
  to?: NavLinkProps['to']
  items?: ReadonlyArray<Omit<MenuItemValue, 'items'>>
}>

export type LanguageMenuItemProps = Readonly<{
  title: string
  href: string
}>

export const useMenuItems = (): ReadonlyArray<MenuItemValue> => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  return React.useMemo(
    () => [
      {
        title: t('layout.menu.items.main'),
        to: routes.Index
      },
      {
        title: t('layout.menu.items.catalog'),
        to: routes.Catalog
      },
      {
        title: t('layout.menu.items.service'),
        items: [
          {
            title: t('layout.menu.items.installment'),
            to: routes.Installment
          },
          {
            title: t('layout.menu.items.exchange'),
            to: routes.Exchange
          },
          {
            title: t('layout.menu.items.delivery'),
            to: routes.Delivery
          },
          {
            title: t('layout.menu.items.commission'),
            to: routes.Commission
          },
          {
            title: t('layout.menu.items.guarantee'),
            to: routes.Guarantee
          }
        ]
      },
      {
        title: t('layout.menu.items.buyback'),
        to: routes.Buyback
      },
      {
        title: t('layout.menu.items.about'),
        to: routes.About
      }
    ],
    [t, routes]
  )
}
