import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { controlClassName } from '@digital-magic/react-common'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { useAddressLink } from '@constants/links'
import { mailAddress, phoneNumber } from '@constants/configuration'
import BackgroundLogo from '@assets/icons/backgroundLogo.svg'
import logoImg from '@assets/icons/logo.svg'
import { Button, Link, MenuItem } from '@mui/material'
import { useTheme } from '@hooks/useTheme'
import { useLanguage } from '@hooks/useLanguage'
import { usePostalAddress } from '@hooks/Translation/usePostalAddress'
import { LinkFacebook, LinkInstagram, LinkViber, LinkWhatsapp, RouterLink } from '@controls/buttons'
import { MenuTrigger } from '@controls/Menu'
import { PageContent } from '@layout/Page'
import { FlexContainer } from '@layout/FlexContainer'
import { menuListProps } from '../constants'
import { useMenuItems } from '../useMenuItems'
import styles from './Footer.module.css'

export const Footer: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const { currentTheme } = useTheme()
  const addressLink = useAddressLink()
  const menuItems = useMenuItems()

  const handleMenuItemClick =
    (to: NavLinkProps['to']) =>
      (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault()
        navigate(to)
      }

  const postalAddress = usePostalAddress()

  return (
    <div itemScope itemType="https://schema.org/Organization">
      <meta itemProp="name" content="CarProf" />
      <FlexContainer fixedDirection justifyContentCenter className={styles.outerContainer}>
        <img src={BackgroundLogo} alt="Background Logo" className={styles.backgroundLogo} />
        <PageContent className={styles.innerContainer}>
          <FlexContainer className={styles.content}>
            {/* Left container */}
            <PageContent className={clsx(styles.column, styles.leftSideColumn)}>
              <RouterLink to={routes.Index}>
                <img
                  width="273px"
                  height="43"
                  className={styles.logo}
                  src={logoImg}
                  alt="Organization Logo"
                  itemProp="logo"
                />
              </RouterLink>
              <span className={styles.subtitle} itemProp="description">
                {t('layout.footer.subtitle')}
              </span>
              <FlexContainer fixedDirection responsiveGap fitWidth>
                <LinkFacebook />
                <LinkInstagram />
                <LinkWhatsapp phoneNumber={phoneNumber} />
                <LinkViber phoneNumber={phoneNumber} />
              </FlexContainer>
              <FlexContainer vertical noGap className={styles.innerColumn}>
                {menuItems.map(({ title, to, items }, index) =>
                  hasValue(to) && isEmpty(items) ? (
                    <NavLink
                      key={`desktop-menu-item-${index}`}
                      to={to}
                      className={({ isActive }) => (isActive ? `${controlClassName.Active} menuItem` : 'menuItem')}
                    >
                      {title}
                    </NavLink>
                  ) : (
                    <MenuTrigger
                      {...menuListProps(currentTheme.palette.mode)}
                      key={`desktop-menu-item-${index}`}
                      renderMenu={() =>
                        items?.map(({ to, title }, subIndex) => (
                          <MenuItem
                            key={`desktop-menu-item-${index}-${subIndex}`}
                            selected={to === location.pathname}
                            onClick={hasValue(to) ? handleMenuItemClick(to) : undefined}
                          >
                            {title}
                          </MenuItem>
                        ))
                      }
                    >
                      {({ openHandler }) => (
                        <Button variant="text" className={styles.menuItem}
                                onClick={(e) => openHandler(e.currentTarget)}>
                          {title}
                        </Button>
                      )}
                    </MenuTrigger>
                  )
                )}
              </FlexContainer>
            </PageContent>

            {/* Right container */}
            <PageContent className={styles.column}>
              <FlexContainer responsiveGap justifyContentSpaceBetween fitWidth className={styles.rightSideRow}>
                <h3 className={styles.header}>{t('layout.footer.title')}</h3>
                <FlexContainer fixedDirection responsiveGap fitWidth className={styles.businessHoursRow}>
                  <FlexContainer fixedDirection responsiveGap fitWidth>
                    <span>{t('layout.header.items.weekdays')}</span>
                    <span itemProp="openingHours">
                      {t('layout.header.items.hours1')}
                    </span>
                  </FlexContainer>
                  <FlexContainer fixedDirection responsiveGap fitWidth>
                    <span>{t('layout.header.items.weekend')}</span>
                    <span itemProp="openingHours">
                      {t('layout.header.items.hours2')}
                    </span>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer vertical noGap className={clsx(styles.innerColumn, styles.contactsContainer)}>
                <Link
                  className={styles.address}
                  href={addressLink}
                  target="_blank"
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <span itemProp="streetAddress">{postalAddress.street} {postalAddress.building}</span>,{' '}
                  <span itemProp="addressLocality">{postalAddress.city}</span>{' '}
                  <meta itemProp="postalCode" content={postalAddress.postalCode} />
                  <meta itemProp="addressCountry" content={postalAddress.country} />
                </Link>
                <Link href={`tel:${phoneNumber}`} itemProp="telephone">
                  {phoneNumber}
                </Link>
                <Link href={`mailto:${mailAddress}`} itemProp="email">
                  {mailAddress}
                </Link>
              </FlexContainer>
            </PageContent>
          </FlexContainer>
          <FlexContainer fixedDirection className={styles.copyrightContainer}>
            <span className={styles.copyright}>{t('layout.footer.text')}</span>
            <RouterLink to={routes.PrivacyPolicy} noWrap className={styles.privacy_policy}>
              {t('layout.footer.privacy_policy')}
            </RouterLink>
          </FlexContainer>
        </PageContent>
      </FlexContainer>
    </div>
  )
}
