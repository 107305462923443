import * as React from 'react'
import { useTranslation } from 'react-i18next'

// TODO: Use `PostalAddress` structure from our model to build address for query string
// export const addressLink = encodeURI(
//   'https://www.google.com/maps/place/Peterburi+tee+71,+11415+Tallinn/@59.4308881,24.8367336,17z/data=!4m15!1m8!3m7!1s0x4692ecbcc369ea49:0x47420c340504e32d!2sPeterburi+tee+71,+11415+Tallinn!3b1!8m2!3d59.4308881!4d24.8367336!16s%2Fg%2F11bw3yy59b!3m5!1s0x4692ecbcc369ea49:0x47420c340504e32d!8m2!3d59.4308881!4d24.8367336!16s%2Fg%2F11bw3yy59b'
// )
export const useAddressLink = (): string => {
  const { t } = useTranslation()
  //TODO to find a way to get only the English translation out of the translations.json
  const address = `${t('app.street')} ${t('app.building')} ${t('app.postalCode')} ${t('app.city')}`.split(' ').join('+')
  return React.useMemo(() => encodeURI(`https://www.google.com/maps/place/${address}`), [address])
}

export const fbLink = encodeURI('https://www.facebook.com/CarProf-Eesti-100076866557075')

export const instaLink = encodeURI('https://www.instagram.com/carprof.ee/')

export const googleLink = encodeURI(
  'https://www.google.com/search?q=CarProf&sca_esv=576391435&sxsrf=AM9HkKmHGeNBtFD8boSUGtJP3AW5dkmjZQ%3A1698215637116&ei=1bY4Zd_UBoCvwPAP9dGOmAo&ved=0ahUKEwifk7WeypCCAxWAFxAIHfWoA6MQ4dUDCBA&uact=5&oq=CarProf&gs_lp=Egxnd3Mtd2l6LXNlcnAiB0NhclByb2YyDBAjGIoFGBMYgAQYJzIHECMYigUYJzIHECMYigUYJzIQEC4YFBivARjHARiHAhiABDILEC4YgAQYxwEYrwEyBRAAGIAEMgUQABiABDILEC4YgAQYxwEYrwEyBRAAGIAEMgUQABiABEj6BlD9Alj9AnABeAGQAQCYAVugAVuqAQExuAEDyAEA-AEBwgIKEAAYRxjWBBiwA-IDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x4692edb5271cf2cb:0xb7755cf597af2afc,1,,,,'
)

export const buildEmailLink = (email: string): string => encodeURI(`mailto:${email}`)

export const buildPhoneLink = (phoneNumber: string): string => encodeURI(`tel:${phoneNumber}`)
export const buildTelegramLink = (phoneNumber: string): string => encodeURI(`https://t.me/${phoneNumber}`)

const generatePrefixedPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.startsWith('+372') ? phoneNumber : `+372${phoneNumber}`

const replaceNonDigits = (phoneNumber: string): string => phoneNumber.replace(/\D/g, '')

const cleanPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.startsWith('+') ? '+' + replaceNonDigits(phoneNumber) : replaceNonDigits(phoneNumber)

export const buildWhatsappLink = (phoneNumber: string): string =>
  `https://wa.me/${encodeURIComponent(generatePrefixedPhoneNumber(cleanPhoneNumber(phoneNumber)).replace('+', ''))}`

export const buildViberLink = (phoneNumber: string): string =>
  `viber://contact?number=${encodeURIComponent(generatePrefixedPhoneNumber(cleanPhoneNumber(phoneNumber)))}`
