import * as React from 'react'
import { fbLink } from '@constants/links'
import SvgFacebookImg from '@assets/icons/fb-bw.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkFacebookProps = Readonly<{
  size?: IconLinkProps['size']
}>

export const LinkFacebook: React.FC<LinkFacebookProps> = ({ size }) => (
  <IconLink href={fbLink} title={fbLink} target="_blank" src={SvgFacebookImg} alt={fbLink} size={size} />
)
