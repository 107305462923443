import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PostalAddress } from '@model/postalAddress'

export const usePostalAddress = (): PostalAddress => {
  const { t } = useTranslation()

  return React.useMemo(
    () => ({
      country: t('app.country'),
      city: t('app.city'),
      street: t('app.street'),
      building: t('app.building'),
      room: t('app.room'),
      state: t('app.state'),
      postalCode: t('app.postalCode')
    }),
    [t]
  )
}
