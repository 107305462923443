import * as React from 'react'
import { instaLink } from '@constants/links'
import SvgInstagramImg from '@assets/icons/inst.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkInstagramProps = Readonly<{
  size?: IconLinkProps['size']
}>

export const LinkInstagram: React.FC<LinkInstagramProps> = ({ size }) => (
  <IconLink href={instaLink} title={instaLink} target="_blank" src={SvgInstagramImg} alt={instaLink} size={size} />
)
