import { hasValue } from '@digital-magic/ts-common-utils'
import { PostalAddress, PostalAddressFormat } from './types'

export const formatterPostalAddressShort = (postalAddress: PostalAddress): string =>
  [postalAddress.street, postalAddress.building, postalAddress.city].join(', ')

export const formatterPostalAddressLong = (postalAddress: PostalAddress): string =>
  [postalAddress.street, postalAddress.building, postalAddress.postalCode,  postalAddress.city, postalAddress.country]
    .filter(hasValue)
    .join(', ')

export const formatterPostalAddress =
  (format: PostalAddressFormat) =>
  (postalAddress: PostalAddress): string => {
    switch (format) {
      case PostalAddressFormat.Long:
        return formatterPostalAddressLong(postalAddress)
      case PostalAddressFormat.Short:
        return formatterPostalAddressShort(postalAddress)
    }
  }
