import * as React from 'react'
import clsx from 'clsx'
import { CookieConsent } from '@forms/CookieConsent/CookieConsent'
import { Menu } from './Menu'
import { Footer } from './Footer'
import { ScrollToTop } from './ScrollToTop/ScrollToTop'
import styles from './Page.module.css'

export type Props = Readonly<{
  id: string
  showMenu?: boolean
  showFooter?: boolean
  className?: string
  disableScrollToTop?: boolean
}> &
  React.PropsWithChildren

const isGmtLoaded = (): boolean => typeof readConsentCookie === 'function'

export const Page: React.FC<Props> = ({
  id,
  showMenu = true,
  showFooter,
  className,
  children,
  disableScrollToTop = false
}) => {
  const [isCookieConsentReady, setIsCookieConsentReady] = React.useState(isGmtLoaded())

  React.useEffect(() => {
    console.debug('Waiting for GMT to load...')
    const interval = setInterval(() => {
      console.debug('Checking if GMT is loaded...')
      if (isGmtLoaded()) {
        setIsCookieConsentReady(true)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id={id} className={clsx(styles.page, 'scrollable-content', className)}>
      {isCookieConsentReady && <CookieConsent />}
      {showMenu && <Menu />}
      <main>{children}</main>
      {disableScrollToTop !== true && <ScrollToTop id={id} />}
      {showFooter !== false && <Footer />}
    </div>
  )
}
