export type PostalAddress = {
  country: string
  city: string
  street: string
  building: string
  room: string | undefined
  state: string
  postalCode: string
}

export enum PostalAddressFormat {
  'Long',
  'Short'
}
