import * as React from 'react'
import styles from './Icon.module.css'
import clsx from 'clsx'

export type IconProps = Readonly<{
  size?: 'medium' | 'large' | 'small' | 'full'  | 'square' | 'link'
  className?: string
}> &
  React.ImgHTMLAttributes<HTMLImageElement>

export const Icon: React.FC<IconProps> = ({ size = 'medium', className, alt, ...props }) => (
  <img
    alt={alt}
    className={clsx(styles.icon, className, {
      [styles.largeIcon]: size === 'large',
      [styles.smallIcon]: size === 'small',
      [styles.fullIcon]: size === 'full',
      [styles.fullIcon]: size === 'link',
      [styles.squareIcon]: size === 'square'
    })}
    {...props}
  />
)
