import * as React from 'react'
import { buildPhoneLink } from '@constants/links'
import { PhoneNumber } from '@api/endpoints'
import { Link } from '@mui/material'

export type LinkPhoneProps = Readonly<{
  phoneNumber: PhoneNumber
  className?: string
}>

export const LinkPhone: React.FC<LinkPhoneProps> = ({ phoneNumber, className }) => (
  <Link href={buildPhoneLink(phoneNumber)} title={phoneNumber} className={className} noWrap>
    {phoneNumber}
  </Link>
)
