import * as React from 'react'
import { buildWhatsappLink } from '@constants/links'
import { PhoneNumber } from '@api/endpoints'
import SvgWhatsappColoredImg from '@assets/icons/social/whatsapp.svg'
import SvgWhatsappBWImg from '@assets/icons/whatsAppIcon.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkWhatsappProps = Readonly<{
  phoneNumber: PhoneNumber
  colored?: boolean
  size?: IconLinkProps['size']
}>
export const LinkWhatsapp: React.FC<LinkWhatsappProps> = ({ phoneNumber, colored = false, size }) => (
  <IconLink
    href={buildWhatsappLink(phoneNumber)}
    title={phoneNumber}
    target="_blank"
    src={colored ? SvgWhatsappColoredImg : SvgWhatsappBWImg}
    alt={phoneNumber}
    size={size}
    color={colored && size == 'square' ? 'green' : 'blank'}
  />
)
