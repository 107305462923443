import * as React from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from '@mui/material'
import { Icon, IconProps } from '@controls/Icon'
import styles from '@controls/Icon/Icon.module.css'

export type IconLinkProps = Readonly<{
  href: LinkProps['href']
  title?: LinkProps['title']
  target?: LinkProps['target']
  rel?: LinkProps['rel']
  src: IconProps['src']
  alt: IconProps['alt']
  size?: IconProps['size']
  className?: string
  color?: 'grey' | 'green' | 'blue' | 'purple' | 'outlined' | 'blank',
  linkProps?: Omit<LinkProps, 'href' | 'title' | 'target' | 'rel' | 'className'>
  iconProps?: Omit<IconProps, 'src' | 'size' | 'alt' >
}>

export const IconLink: React.FC<IconLinkProps> = ({
  size,
  href,
  title,
  target,
  rel,
  src,
  alt,
  className,
  linkProps,
  iconProps,
  color = 'grey'
}) => (
  <div className={styles.link}>
    <Link
      href={href}
      title={title}
      target={target}
      rel={rel}
      className={clsx(styles.icon, className, {
        [styles.largeIcon]: size === 'large',
        [styles.smallIcon]: size === 'small',
        [styles.squareIcon]: size === 'square',
        [styles.linkButton]: size === 'link',
        [styles.fullButton]: size === 'full'
      }, {
        [styles.purple]: color === 'purple',
        [styles.grey]: color === 'grey',
        [styles.green]: color === 'green',
        [styles.blue]: color === 'blue',
        [styles.outlined]: color === 'outlined'
      })}
      {...linkProps}
    >
      <Icon size={size} src={src} alt={alt} {...iconProps} />
      {size === 'full' || size === 'link' ? title : ''}
    </Link>
  </div>
    )
